import React, { FC } from 'react';

import { Parallelogram } from 'components/atoms/Geometric/Parallelogram/Parallelogram';

import { StyledParallelogramsContainer } from './Parallelograms.styles';

export const Parallelograms: FC = () => (
  <StyledParallelogramsContainer>
    <Parallelogram width="30px" height="20px" top="20px" left="11%" isMirrored variant="green_100" />
    <Parallelogram width="30px" height="20px" top="20px" left="50%" isMirrored variant="green_100" />
  </StyledParallelogramsContainer>
);
