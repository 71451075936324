import React, { FC } from 'react';
import { StyledIndicators, StyledIndicatorContainer, StyledIndicator } from './Pagination.styles';

type PaginationItems = Array<{ id?: string }>;

type PaginationProps = {
  items: PaginationItems;
  currentItem: number;
  setItem: (newItem: number, newDirection?: number) => void;
  marginTop?: string;
  color: string;
};

export const Pagination: FC<PaginationProps> = ({ items, currentItem, setItem, marginTop, color }) => (
  <StyledIndicators marginTop={marginTop}>
    {items.map((item, index) => (
      <StyledIndicatorContainer key={index} onClick={() => setItem(index)}>
        <StyledIndicator color={color} isSelected={index === currentItem} />
      </StyledIndicatorContainer>
    ))}
  </StyledIndicators>
);
