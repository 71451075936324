import React, { FC } from 'react';
import type { MDXRemoteSerializeResult } from 'next-mdx-remote/dist/types';

import { LinkedButton } from 'components/atoms/Button/LinkButton/LinkButton';
import Typography from 'components/atoms/Typography/Typography';
import { Marked } from 'components/Marked/Marked';

import {
  StyledReviewContainer,
  StyledReviewPersonWrapper,
  StyledReviewWrapper,
  StyledCheckClutchReviewsWrapper,
  StyledReviewPositionWrapper
} from './ReviewsSlide.styles';

export type ReviewProps = {
  id?: string;
  author: MDXRemoteSerializeResult;
  position?: MDXRemoteSerializeResult;
  company?: string;
  review: MDXRemoteSerializeResult;
  href?: string;
  buttonCopy?: string;
};

export const ReviewsSlide: FC<ReviewProps> = ({ review, author, position, company, href, buttonCopy }) => (
  <StyledReviewContainer>
    <StyledReviewWrapper>
      <Typography variant="h4" weight="400" color="darkerGreen">
        <Marked>{review}</Marked>
      </Typography>
      <StyledReviewPersonWrapper>
        <Typography variant="h6" weight="400" renderAs="span" color="darkerGreen">
          <Marked>{author}</Marked>
        </Typography>
        <StyledReviewPositionWrapper>
          <Typography variant="body3" weight="400" renderAs="span" color="darkerGreen">
            <Marked>{position}</Marked>
          </Typography>
          {company && (
            <>
              <Typography variant="body3" weight="400" renderAs="span" color="darkerGreen">
                at
              </Typography>
              <Typography variant="body3" weight="600" renderAs="span" color="darkerGreen">
                <Marked>{company}</Marked>
              </Typography>
            </>
          )}
        </StyledReviewPositionWrapper>
      </StyledReviewPersonWrapper>
      {href && (
        <StyledCheckClutchReviewsWrapper>
          <LinkedButton href={href} variant="tertiary-white">
            {buttonCopy || 'Review'}
          </LinkedButton>
        </StyledCheckClutchReviewsWrapper>
      )}
    </StyledReviewWrapper>
  </StyledReviewContainer>
);
