import styled from 'styled-components';

import { mq } from 'theme/media';

export const StyledReviewContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;

  ${mq.medium`
    max-width: 100%;
  `}
`;

export const StyledReviewWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlack};
  text-align: center;

  ${mq.medium`
    max-width: 600px;
  `}
`;

export const StyledReviewPersonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)};
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledReviewPositionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;

export const StyledCheckClutchReviewsWrapper = styled.div`
  display: none;

  ${mq.medium`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(6)};
  `}
`;
