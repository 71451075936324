import styled from 'styled-components';

export const StyledParallelogramsContainer = styled.div`
  position: relative;
  width: 80px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
