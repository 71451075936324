import React, { FC, useRef, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';

type SliderProps = {
  children: ReactNode;
  numberOfItems: number;
  currentItem: number;
  setItem: (newItem: number, newDirection?: number) => void;
  direction: number;
};

const X_OFFSET = 500;
const THRESHOLD = 50;

const variants = {
  enter: (direction: number) => ({
    x: direction > 0 ? X_OFFSET : -X_OFFSET,
    opacity: 0,
    display: 'block',
    transition: { delay: 0.3, duration: 0.4 }
  }),
  active: {
    x: 0,
    opacity: 1,
    display: 'block',
    transition: { delay: 0.3, duration: 0.4 }
  },
  exit: (direction: number) => ({
    x: direction > 0 ? -X_OFFSET : X_OFFSET,
    opacity: 0,
    display: 'none',
    transition: { delay: 0.3, duration: 0.4 }
  })
};

export const Slider: FC<SliderProps> = ({ children, numberOfItems, currentItem, setItem, direction }) => {
  const hasPaginated = useRef(false);

  const detectPaginationGesture = (event, { offset }) => {
    if (hasPaginated.current) {
      return;
    }

    let newItem = currentItem;

    if (offset.x < -THRESHOLD) {
      newItem = currentItem + 1;
    } else if (offset.x > THRESHOLD) {
      newItem = currentItem - 1;
    }

    if (newItem !== currentItem) {
      hasPaginated.current = true;
      newItem = wrap(0, numberOfItems, newItem);
      setItem(newItem, offset.x < 0 ? 1 : -1);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        key={currentItem}
        variants={variants}
        initial="enter"
        animate="active"
        exit="exit"
        drag="x"
        dragDirectionLock
        onDrag={detectPaginationGesture}
        onDragStart={() => (hasPaginated.current = false)}
        onDragEnd={() => (hasPaginated.current = true)}
        dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
        custom={direction}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
