import React, { FC, useState } from 'react';
import Image from '@next/image';
import Typography from 'components/atoms/Typography/Typography';

import useMedia from 'hooks/useMedia';
import { Slider } from 'components/Slider/Slider';
import { Pagination } from 'components/Slider/Pagination';
import ClutchWidget from 'components/atoms/ClutchWidget/ClutchWidget';
import { ClutchProps } from 'utils/types/Clutch';
import theme from 'theme/theme';
import GeoShapes from './GeoShapes';
import { ReviewProps, ReviewsSlide } from './ReviewsSlide/ReviewsSlide';
import { Parallelograms } from './Parallelograms/Parallelograms';
import {
  StyledReviewsSlider,
  StyledReviewsRow,
  StyledClutchLogoWrapper,
  StyledClutchRating,
  StyledSlider,
  StyledParallelogramsContainer,
  StyledBulletsWrapper,
  StyledTitleWrapper,
  StyledContainer
} from './Reviews.styles';

export type ReviewsProps = {
  id?: string;
  title?: string;
  reviews: Array<ReviewProps>;
  buttonCopy?: string;
  clutch?: ClutchProps;
  shouldShowTitle?: boolean;
};

const Reviews: FC<ReviewsProps> = ({ reviews, buttonCopy, title, clutch, shouldShowTitle = true }) => {
  const [[currentItem, direction], setCurrentItem] = useState([0, 0]);
  const { isMobile } = useMedia();
  const setItem = (newItem: number, newDirection: number) => {
    if (!newDirection) {
      newDirection = newItem - currentItem;
    }
    setCurrentItem([newItem, newDirection]);
  };

  return (
    <StyledContainer>
      <StyledReviewsRow>
        {shouldShowTitle && !!title && (
          <StyledTitleWrapper>
            <Typography weight="700" variant="h2">
              {title}
            </Typography>
          </StyledTitleWrapper>
        )}
        <StyledReviewsSlider>
          {!isMobile && <GeoShapes />}
          <StyledClutchRating>
            <ClutchWidget {...clutch} option="square" />
          </StyledClutchRating>
          <StyledParallelogramsContainer>
            <Parallelograms />
          </StyledParallelogramsContainer>
          <StyledClutchLogoWrapper>
            <Image src="/img/logos/clutch.png" alt="Logo of Clutch website" width={248} height={280} />
          </StyledClutchLogoWrapper>
          <StyledSlider>
            {reviews.length > 1 ? (
              <Slider numberOfItems={reviews.length} currentItem={currentItem} direction={direction} setItem={setItem}>
                <ReviewsSlide {...reviews[currentItem]} buttonCopy={buttonCopy} />
              </Slider>
            ) : (
              <ReviewsSlide {...reviews[0]} buttonCopy={buttonCopy} />
            )}
          </StyledSlider>
        </StyledReviewsSlider>
        <StyledBulletsWrapper>
          {reviews.length > 1 && (
            <Pagination
              items={reviews}
              currentItem={currentItem}
              setItem={setItem}
              color={theme.colors.green_100}
              marginTop="30px"
            />
          )}
        </StyledBulletsWrapper>
      </StyledReviewsRow>
    </StyledContainer>
  );
};

export default Reviews;
