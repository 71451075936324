import styled from 'styled-components';

export const StyledIndicatorContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const StyledIndicator = styled.div<{ isSelected: boolean; color: string }>`
  background: ${({ theme, isSelected, color }) => (isSelected ? color : theme.colors.lightGray)};
  cursor: pointer;
  height: 16px;
  width: 16px;
  transition: background 0.3s linear 0s;
`;

export const StyledIndicators = styled.div<{
  marginTop?: string;
}>`
  display: flex;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop || 0};
`;
