import React, { FC } from 'react';

import { Shape } from 'components/atoms/Shape/Shape';

const GeoShapes: FC = () => (
  <>
    <Shape src="/shapes/main_slider_small.png" width={11} height={12} position="absolute" left="-60px" top="160px" />
    <Shape src="/shapes/main_slider_small.png" width={11} height={12} position="absolute" right="-60px" top="320px" />
    <Shape
      src="/shapes/reviews_corner.png"
      width={100}
      height={100}
      position="absolute"
      right="-50px"
      top="366px"
      zIndex={2}
    />
  </>
);

export default GeoShapes;
