import styled, { DefaultTheme } from 'styled-components';

import { ParallelogramProps } from './Parallelogram';

const parallelogramVariant = (theme: DefaultTheme) => ({
  purple: {
    background: theme.gradients.primary
  },
  blue: {
    background: `linear-gradient(90deg, ${theme.colors.green_100} 0%, ${theme.colors.light} 100%)`
  },
  'violet-gray': {
    background: theme.colors.grey_25
  },
  green_100: {
    background: theme.colors.green_100
  }
});

export const StyledParallelogramContainer = styled.div<Partial<ParallelogramProps>>`
  position: absolute;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${(props) => props.zIndex};
  transform: ${({ isMirrored }) => (isMirrored ? 'skewY(135deg)' : 'skewY(45deg)')};
`;

export const StyledParallelogramShape = styled.div<Partial<ParallelogramProps>>`
  width: 100%;
  height: 100%;
  ${({ variant, theme }) => parallelogramVariant(theme)[variant]};
`;
