import React, { FC } from 'react';

import { StyledParallelogramContainer, StyledParallelogramShape } from './Parallelogram.styles';

export type ParallelogramProps = {
  width: string;
  height?: string;
  top?: string;
  left?: string;
  isMirrored?: boolean;
  variant?: 'purple' | 'blue' | 'violet-gray' | 'green_100';
  zIndex?: number;
};

export const Parallelogram: FC<ParallelogramProps> = ({
  width,
  height = '100px',
  top,
  left,
  isMirrored,
  variant,
  zIndex
}: ParallelogramProps) => (
  <StyledParallelogramContainer
    isMirrored={isMirrored}
    width={width}
    height={height}
    top={top}
    left={left}
    zIndex={zIndex}
  >
    <StyledParallelogramShape variant={variant} />
  </StyledParallelogramContainer>
);
